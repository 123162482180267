<template>
	<form @submit.prevent="submitForm" class="text-darkgreen">
		<div class="form-group">
			<div class="row">
				<div class="col">
					<label>Voornaam</label>
					<input
						name="Voornaam"
						type="text"
						class="form-control"
						v-model="orderInfo.Voornaam"
						v-validate="'required'"
						:class="{ input: true, 'is-invalid': errors.has('Voornaam') }"
					/>
				</div>
				<div class="col">
					<label>Achternaam</label>
					<input
						name="Achternaam"
						type="text"
						class="form-control"
						v-model="orderInfo.Achternaam"
						v-validate="'required'"
						:class="{ input: true, 'is-invalid': errors.has('Achternaam') }"
					/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Email</label>
			<input
				name="email"
				type="email"
				class="form-control"
				v-model="orderInfo.Email"
				v-validate="'required|email'"
				:class="{ input: true, 'is-invalid': errors.has('email') }"
			/>
		</div>

		<div class="row">
			<div class="col">
				<label>Straatnaam</label>
				<input
					name="straat"
					type="text"
					class="form-control"
					v-model="orderInfo.Straat"
					v-validate="'required'"
					:class="{ input: true, 'is-invalid': errors.has('straat') }"
				/>
			</div>

			<div class="col-3">
				<label>Huisnummer</label>
				<input
					name="huisnr"
					type="text"
					class="form-control"
					v-model="orderInfo.Huisnummer"
					v-validate="'required'"
					:class="{ input: true, 'is-invalid': errors.has('huisnr') }"
				/>
			</div>
		</div>
		<br />

		<div class="row">
			<div class="col-3">
				<label>Postcode</label>
				<input
					name="postcode"
					type="text"
					class="form-control"
					v-model="orderInfo.Postcode"
					v-validate="'required'"
					:class="{ input: true, 'is-invalid': errors.has('postcode') }"
				/>
			</div>

			<div class="col">
				<label>Plaats</label>
				<input
					name="plaats"
					type="text"
					class="form-control"
					v-model="orderInfo.Plaats"
					v-validate="'required'"
					:class="{ input: true, 'is-invalid': errors.has('plaats') }"
				/>
			</div>
		</div>
		<br />

		<div class="row">
			<div class="col-6">
				<label>&nbsp;</label>
				<button type="submit" class="btn btn-success form-control">Doorgaan naar betaling</button>
			</div>
		</div>
	</form>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

import Vue from 'vue';

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

export default {
	name: 'Form',
	props: {
		info: {
			type: Object,
		},
	},

	data: () => ({
		orderInfo: {
			Onderwerp: 'bestelling simonebusscher.nl',
			Voornaam: '',
			Achternaam: '',
			Email: '',
			Straat: '',
			Huisnummer: '',
			Postcode: '',
			Plaats: '',
			Datum: '',
		},
		empty: {},
		URL: 'https://www.simonebusscher.nl',
	}),

	created() {
		console.clear();
		// console.log(this.info);

		// keep an empty copy of the object
		this.empty = this.copyObject(this.orderInfo);

		// for testing
		this.orderInfo = {
			Onderwerp: 'bestelling simonebusscher.nl',
			Voornaam: 'Raymond',
			Achternaam: 'Verbruggen',
			Email: 'mail@focussing.nl',
			Straat: 'Mispel',
			Huisnummer: '8',
			Postcode: '7621 WB',
			Plaats: 'Borne',
			Datum: '',
		};

		// let now = dayjs();
		// let end = dayjs('2019-06-01');
		// console.log(dayjs().toISOString());
		// console.log(now.isBefore(end));
	},

	methods: {
		submitForm: function () {
			// console.log(this.errors.items);
			this.$validator.validateAll().then((result) => {
				if (result) {
					// create ideal payment
					this.createPayment();
				} else {
					// error message
					var msg = 'Niet alle velden zijn ingevuld.<br />Vul de rood omlijnde velden alsnog in.';
					this.$toast(msg, {
						horizontalPosition: 'center',
						verticalPosition: 'vcenter',
						// transition: 'slide-up',
						button: 'OK',
					});
				}
			});
		},

		createPayment: async function () {
			const formData = new FormData();
			formData.append('currency', 'EUR');
			formData.append('value', this.info.totalPrice.toFixed(2));
			formData.append('description', 'Order #' + new Date().getTime());
			formData.append('webhookUrl', this.URL + '/php/mollie-api-php/examples/webhook.php');
			formData.append('redirectUrl', this.URL + '/redirect');

			this.orderInfo.Datum = dayjs().format('YYYY-MM-DD HH:mm:ss');
			let checkoutUrl;
			let orderId;

			// payment
			await axios
				.post(this.URL + '/php/mollie-api-php/examples/ideal-payment.php', formData)
				.then((result) => {
					console.log(result.data);
					checkoutUrl = result.data._links.checkout.href;
					orderId = result.data.id;
					console.log('checkoutUrl', checkoutUrl);
					console.log('orderId', orderId);

					// save to local storage
					sessionStorage.setItem('orderId', JSON.stringify(orderId));
					sessionStorage.setItem('orderInfo', JSON.stringify(this.orderInfo));

					// clear form
					this.orderInfo = this.copyObject(this.empty);
					this.$validator.reset();

					// proceed to checkout
					window.location.href = checkoutUrl;
				})
				.catch((error) => {
					console.log(error);
				});
		},

		copyObject: function (obj) {
			return JSON.parse(JSON.stringify(obj));
		},
	},
};
</script>

<style>
input {
	color: #007da5;
}

.dg-btn {
	border-width: 1px;
	font-weight: normal;
}
</style>
